import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

import moviebuff from "../utils/moviebuff.jpg"
import megrooms from "../utils/megrooms.jpg"
import hollywoodburgers from "../utils/hollywood-burgers.jpg"
import picstagram from "../utils/picstagram.jpg"
import tictactoe from "../utils/tictactoe.png"
import urlshortner from "../utils/url-shortner.png"

const Projects = () => {
  const title = "Meghna Srivastava"

  const page = {}
  page.title = "Projects"
  page.content = `<h2>Some of my projects from last year..</h2>
<hr>
<div class="wp-block-columns has-2-columns">
<div>
<h4 id="mce_3">&#8220;Moviebuff&#8221;</h4>
A React app featuring all the latest movies by consuming the TMDB API. The user can see a list of movies, the details and the casting of any particular movie. There's a search functionality as well to fetch a specific result.
<br><br>
<a href="https://meg-moviebuff.netlify.com/" target="_blank" rel="noreferrer noopener"><img src=${moviebuff} class="project-image"></img></a>
<br>
<strong>Technologies used: </strong>
HTML / CSS / React.js / Prop-types
<h4 id="mce_26" class="projects_links"><a href="https://github.com/meghna-saxena/moviebuff" target="_blank" rel="noreferrer noopener">GITHUB</a> | <a href="https://meg-moviebuff.netlify.com/" target="_blank" rel="noreferrer noopener">LIVE DEMO</a></h4>
<hr class="wp-block-separator projects_separator is-style-default" />
</div>
<div class="wp-block-column">
<h4>&#8220;Meg Rooms&#8221;</h4>
It's like a room-booking web-app at your vacation spot! User can see featured rooms. They can filter the rooms based on type, price, size etc. No API has been used, I built it by using the data in a JSON format from my local repository.
<br><br>
<a href="https://meg-rooms.netlify.com/" target="_blank" rel="noreferrer noopener"><img src=${megrooms} class="project-image"></img></a>
<br>
<strong>Technologies used:</strong>
HTML / CSS/ React.js/ Styled-Components
<h4 id="mce_29" class="projects_links"><a href="https://github.com/meghna-saxena/resort-website" target="_blank" rel="noreferrer noopener">GITHUB</a> | <a href="https://meg-rooms.netlify.com/" target="_blank" rel="noreferrer noopener">LIVE DEMO</a></h4>
<hr class="wp-block-separator projects_separator is-style-default" />
</div>
</div>
<div class="wp-block-columns has-2-columns">
<div class="wp-block-column">
<h4>&#8220;Picstagram&#8221;</h4>
A simple picture gallery app resembling instagram minimalist-design built using React and Redux.
<br><br>
<a href="https://picstagram.netlify.com/" target="_blank" rel="noreferrer noopener"><img src=${picstagram} class="project-image"></img></a>
<br>
<strong>Technologies used: </strong>
HTML / CSS / React.js / Redux 
<h4 id="mce_46" class="projects_links"><a href="https://github.com/meghna-saxena/picstagram" target="_blank" rel="noreferrer noopener">GITHUB</a> | <a href="https://picstagram.netlify.com/" target="_blank" rel="noreferrer noopener">LIVE DEMO</a></h4>
<hr class="wp-block-separator projects_separator is-style-default" />
</div>
<div class="wp-block-column">
<h4>&#8220;Megminiurl Shortner&#8221;</h4>
An URL-shortner built by using vanilla frontend technolgies.
<br><br>
<a href="https://megminiurl.netlify.com/" target="_blank" rel="noreferrer noopener"><img src=${urlshortner} class="project-image"></img></a>
<br>
<strong>Technologies used: </strong>
HTML / CSS / JavaScript / Local Storage
<h4 id="mce_46" class="projects_links"><a href="https://github.com/meghna-saxena/megminiurl" target="_blank" rel="noreferrer noopener">GITHUB</a> | <a href="https://megminiurl.netlify.com/" target="_blank" rel="noreferrer noopener">LIVE DEMO</a></h4>
<hr class="wp-block-separator projects_separator is-style-default" />
</div>
<div class="wp-block-column">
<h4>&#8220;Tic Tac Toe&#8221;</h4>
Tic-tac-toe game for 2 players on a 3 x 3 board size
<br><br>
<a href="https://meg-tictac.netlify.com/" target="_blank" rel="noreferrer noopener"><img src=${tictactoe} class="project-image"></img></a>
<br>
<strong>Technologies used: </strong>
HTML / CSS / JavaScript
<h4 id="mce_46" class="projects_links"><a href="https://meg-tictac.netlify.com/" target="_blank" rel="noreferrer noopener">LIVE DEMO</a></h4>
<hr class="wp-block-separator projects_separator is-style-default" />
</div>
<div class="wp-block-column">
<h4>&#8220;Hollywood Burgers&#8221;</h4>
My first major project when I was learning React. The app lets the user to choose what type of burger they want - with what fillings (meat/salad/cheese). I also added the functionilty to submit your order and checkout. It then navigates to the "Orders" page where you can see a list of all the orders.
<br><br>
<a href="https://meg-moviebuff.netlify.com/" target="_blank" rel="noreferrer noopener"><img src=${hollywoodburgers} class="project-image"></img></a>
<br>
<strong>Technologies used: </strong>
HTML / CSS / React.js / Redux

<h4 class="projects_links"><a href="https://github.com/meghna-saxena/hollywood-burgers" target="_blank" rel="noreferrer noopener">GITHUB</a> | <a href="https://make-your-burger.herokuapp.com/" target="_blank" rel="noreferrer noopener">LIVE DEMO</a></h4>
<hr class="wp-block-separator projects_separator is-style-default" />
</div>
<a class="button button__primary" href="https://github.com/meghna-saxena" target="_blank"><button style="all: unset">See more projects on Github</button></a>
</div>`

  return (
    <Layout location={"/projects"} title={title}>
      <SEO title={page.title} />
      <div
        dangerouslySetInnerHTML={{ __html: page.content }}
        className="about"
      ></div>
    </Layout>
  )
}

export default Projects
